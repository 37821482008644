export function logSubscription() {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'GAevent',
      eventCategory: 'Form Submit',
      eventAction: 'Newsletter Registration',
      eventLabel: '',
    });
  }
}
