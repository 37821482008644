import fetch from 'isomorphic-fetch';

export function subscribeToNewsletter(baseUrl, siteId, email) {
  const encodedEmail = btoa(email); // Encode to base64, otherwise the request gets blocked in WAF
  return fetch(`${baseUrl}/subscription/subscribe/${siteId}/${encodedEmail}`)
  .then((response) => {
    if (response.status >= 400) {
      throw new Error('Bad response from server');
    }
  });
}
