import PropTypes from 'prop-types';

import './linkList.scss';

const LinkList = ({ links, ...rest }) => {
  LinkList.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
      href: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      rel: PropTypes.string,
    })).isRequired,
  };

  return (
    <ul className="footer-link-list" {...rest}>
      {links.map((link) => {
        return (
          <li className="footer-link-list__item" key={link.name}>
            <a className="footer-link-list__link" href={link.href} rel={link.rel}>{link.name}</a>
          </li>
        );
      })}
    </ul>
  );
};

export default LinkList;
